import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style';
import Logo from '../../assets/logo.png';
import Menu1 from '../../assets/icons/menu1.png';
import Menu2 from '../../assets/icons/menu2.png';
import Menu3 from '../../assets/icons/menu3.png';
import Menu4 from '../../assets/icons/menu4.png';

const MOBILENAV = () => (
	<div class={style.footerMenu}>
		<nav>
			<Link activeClassName={style.active} href="/"> <span> <img src={Menu1}  alt="icon"/> </span> Home</Link>
			<Link activeClassName={style.active} href="/library"> <span> <img src={Menu2}  alt="icon"/></span> Library </Link>
			<Link activeClassName={style.active} href="/bonus"> <span> <img src={Menu3}  alt="icon"/></span> Bonus </Link>
			<Link activeClassName={style.active} href="/support"> <span> <img src={Menu4}  alt="icon"/></span> Support </Link>
		</nav>
	</div>
);

export default MOBILENAV;
