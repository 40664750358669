import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style';
import Logo from '../../assets/logo.png';
import Menu1 from '../../assets/icons/menu1.png';
import Menu2 from '../../assets/icons/menu2.png';
import Menu3 from '../../assets/icons/menu3.png';
import Menu4 from '../../assets/icons/menu4.png';

const SIDEBAR = () => (
	<div class={style.menubar}>
		
		<Link class={style.logobg} href="/"> <img src={Logo}  alt="img" class={style.logo}/> </Link>
		<nav>
		
			<Link activeClassName={style.active} href="/"> <span  class={style.icon} > <img src={Menu1} alt="icon"/> </span> <span class={style.icontext}> Home </span> </Link>
			<Link activeClassName={style.active} href="/library"> <span  class={style.icon} > <img src={Menu2}  alt="icon"/></span><span class={style.icontext}> Library </span> </Link>
			<Link activeClassName={style.active} href="/bonus" id="bonus"> <span class={style.icon} > <img src={Menu3}   alt="icon"/></span><span class={style.icontext}> Bonus</span> </Link>
			<Link activeClassName={style.active} href="/support"> <span class={style.icon}> <img src={Menu4}    alt="icon"/></span><span class={style.icontext}> Support</span>  </Link>
		</nav>
	 
	</div>
);

export default SIDEBAR;
