import { h, Component } from 'preact';
import { Router } from 'preact-router';
import { Provider } from '@preact/prerender-data-provider';
import SIDEBAR from './sidebar';
import MOBILENAV from './mobilenav';
import NotFoundPage from '../routes/notfound';

// Code-splitting is automated for routes
import Home from '../routes/home';
import Library from '../routes/library';
import Bonus from '../routes/bonus';
import Support from '../routes/support';
import BonusWorkout from '../routes/bonus-workout';
import BonusYoga from '../routes/bonus-yoga';
import BodyWeightExerciseProgram1 from '../routes/body-weight-exercise-program-1';
import BodyWeightExerciseProgram2 from '../routes/body-weight-exercise-program-2';
import BodyWeightExerciseProgram3 from '../routes/body-weight-exercise-program-3';
import DeskExercises1 from '../routes/desk-exercises-1/';
import DeskExercises2 from '../routes/desk-exercises-2/';
import DeskExercises3 from '../routes/desk-exercises-3/';
import Yogaburn1 from '../routes/yoga-burn-1/';
import Yogabasics1 from '../routes/yoga-basics-1';
import Yogabasics2 from '../routes/yoga-basics-2';
import Flexibility from '../routes/flexibility';
import Match from 'preact-router/match';

export default class App extends Component {

	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.currentUrl = e.url;
		console.log(this.currentUrl);
	};

	render(props) {
		return (
			<Provider value={props}>
				<div id="app">
					<SIDEBAR />
					<div class="mainbody">
					<Router onChange={this.handleRoute}>
						<Home path="/" />
						<Library path="/library/" />
						<Bonus path="/bonus" />
						<Support path="/support/" />
						<BonusWorkout path="/bonus-workout/" />
						<BonusYoga path="/bonus-yoga/" />
						<BodyWeightExerciseProgram1 path="/body-weight-exercise-program-1/" />
						<BodyWeightExerciseProgram2 path="/body-weight-exercise-program-2/" />
						<BodyWeightExerciseProgram3 path="/body-weight-exercise-program-3/" />
						<DeskExercises1 path="/desk-exercises-1/" />
						<DeskExercises2 path="/desk-exercises-2/" />
						<DeskExercises3 path="/desk-exercises-3/" />
						<Yogaburn1 path="/yoga-burn-1/" />
						<Yogabasics1 path="/yoga-basics-1/" />
						<Yogabasics2 path="/yoga-basics-2/" />
						<Flexibility path="/flexibility/" />
						<NotFoundPage type="404" default />
					</Router>
					</div>
					<MOBILENAV />
				</div>
			</Provider>
		);
	}
}
